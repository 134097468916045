//   ____                       _ _
//  / ___|  ___  ___ _   _ _ __(_) |_ _   _
//  \___ \ / _ \/ __| | | | '__| | __| | | |
//   ___) |  __/ (__| |_| | |  | | |_| |_| |
//  |____/ \___|\___|\__,_|_|  |_|\__|\__, |
//                                    |___/
import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    error: null,
    isAuthenticated: false,
    token: localStorage.getItem('token') || '',
    user: null
  },
  getters: {
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    user (state) {
      if (!state.user) {
        if (state.token !== '') {
          const jwtData = JSON.parse(atob(state.token.split('.')[1]))
          return jwtData
        } else {
          return null
        }
      } else {
        return state.user
      }
    },
    isAuthenticated (state) {
      if (state.token === '') {
        return false
      } else {
        return true
      }
    },
    hasRole: (state) => (role) => {
      let user = state.user
      if (!user) {
        user = JSON.parse(atob(state.token.split('.')[1]))
      }
      if (user) {
        if (user.roles.indexOf(role) === -1) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  },
  mutations: {
    AUTHENTICATING_SUCCESS: function (state, token) {
      state.error = null
      state.isAuthenticated = true
      state.token = token
      // Token deserialisieren und als User Objekt speichern

      const jwtData = JSON.parse(atob(token.split('.')[1]))

      state.user = jwtData
      // Localstorage und Axios
      localStorage.setItem('token', token)
    },
    AUTHENTICATING_ERROR: function (state, error) {
      state.error = error
      state.isAuthenticated = false
      localStorage.removeItem('token')
      state.token = ''
      state.user = null
    },
    AUTHENTICATING_LOGOUT: function (state) {
      state.error = null
      state.isAuthenticated = false
      state.token = ''
      state.user = null
      localStorage.removeItem('token')
      delete axios.defaults.headers.common.authorization
    }
  },
  actions: {
    AUTH_LOGIN: ({ commit, dispatch }, user) => {
      return Vue.axios.post('/auth/login', {
        username: user.username,
        password: user.password
      }).then(function (response) {
        // Erfolgreiche Authentifizierung
        commit('AUTHENTICATING_SUCCESS', response.data.token)
      })
        .catch(function (error) {
          // Es soll trotz eines 401 den Error aus dem Response Body angezeigt werden
          if (error.response.status === 401) {
            const data = error.response.data
            if (Vue._.has(data, 'message')) {
              commit('AUTHENTICATING_ERROR', data.message)
            } else {
              // Sollte nicht eintreten, malformed json response
              commit('AUTHENTICATING_ERROR', error)
            }
          } else {
            // Anderer HTTP-Status-Code (zum Beispiel 500)
            commit('AUTHENTICATING_ERROR', error)
          }
        })
    },
    AUTH_LOGOUT: ({ commit, dispatch }) => {
      return commit('AUTHENTICATING_LOGOUT')
    }
  }
}
