<template>
  <div class="material mb-3 border px-3 py-1 mx-0" :id="randomId">
    <CRow class="material full-repeater-row">
      <input type="hidden" name="auto" v-model="localArbeitszeit.auto"/>
      <input type="hidden" name="tageskalenderid" v-model="localArbeitszeit.tageskalenderdid"/>
      <CCol sm="10" md="4" class="p-0">
        <CRow>
          <CCol class="mt-3" sm="12" md="6">
            <ArbeitszeitDatum v-model="localArbeitszeit.datum" form-label="Datum" />
          </CCol>
          <CCol class="mt-3" sm="12" md="6">
            <ArbeitszeitNumber :rules="rules" v-model="localArbeitszeit.dauer" placeholder="Dauer" form-label="Dauer" />
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" md="3" class="mt-3">
        <MitarbeiterAuswahl v-model="localArbeitszeit.mitarbeiter" form-label="Mitarbeiter*" />
      </CCol>
      <CCol sm="12" md="3" class="mt-3 mb-4">
        <ArbeitszeitText v-model="localArbeitszeit.taetigkeit" form-label="Tätigkeit" rules="" />
      </CCol>
      <CCol class="mt-4 text-right p-0 m-auto" sm="10" md="2">
        <CButton
          color="danger"
          variant="outline"
          size="lg"
          class="ml-2 center vertical-middle"
          v-c-tooltip="{content: 'Löschen', placement:'left'}"
          v-on:click="deleteEntry(randomId)"
        >
          <CIcon name="cil-trash"/>
        </CButton>
      </CCol>
    </CRow>
    <CRow class="tageskalender d-sm-down-none" v-if="isAuto() && localArbeitszeit.bilder.length > 0">
      <CCol sm="2" class="ml-3 font-italic font-sm">Bilder aus Tageskalender:</CCol>
      <CCol sm="1" v-for="(bild, index) in localArbeitszeit.bilder" v-bind:key="'bild' + index">
        <Bild :bild="bild" type="tageskalender"/>
      </CCol>
    </CRow>
    <CRow class="bildupload mt-3" v-if="!isAuto()">
      <CCol sm="2" class="font-italic font-sm">Optionaler Bild-Upload:</CCol>
      <CCol sm="2"><ArbeitszeitImage v-model="localArbeitszeit.image01" form-label-class="d-none"/></CCol>
      <CCol sm="2"><ArbeitszeitImage v-model="localArbeitszeit.image02" form-label-class="d-none"/></CCol>
      <CCol sm="2"><ArbeitszeitImage v-model="localArbeitszeit.image03" form-label-class="d-none"/></CCol>
      <CCol sm="2"><ArbeitszeitImage v-model="localArbeitszeit.image04" form-label-class="d-none"/></CCol>
      <CCol sm="2"><ArbeitszeitImage v-model="localArbeitszeit.image05" form-label-class="d-none"/></CCol>
    </CRow>
  </div>
</template>

<script>
import ArbeitszeitDatum from '@/components/FormElements/Date'
import ArbeitszeitNumber from '@/components/FormElements/Number'
import MitarbeiterAuswahl from '@/components/MitarbeiterAuswahl'
import ArbeitszeitText from '@/components/FormElements/Text'
import ArbeitszeitImage from '@/components/FormElements/Image'
import Bild from '@/components/Bild'

export default {
  name: 'formular-arbeitszeit',
  components: {
    ArbeitszeitNumber,
    ArbeitszeitText,
    ArbeitszeitDatum,
    MitarbeiterAuswahl,
    ArbeitszeitImage,
    Bild
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      rules: 'required',
      randomId: 'repeater-row-arbeitszeit-' + this.getRandomInt(10000)
    }
  },
  mounted () {
    // Defaults Faken für vue-repeater
    this.$emit('input', this.localArbeitszeit)
  },
  watch: {
    localArbeitszeit: function () {
      this.$emit('input', this.localArbeitszeit)
    }
  },
  computed: {
    localArbeitszeit () {
      const user = this.$store.getters['security/user']

      let setDefaults = {}
      if (this.value) {
        setDefaults = this.value
      }

      setDefaults.randomId = this.randomId

      const datum = new Date()
      if (!('datum' in setDefaults)) { setDefaults.datum = datum }
      if (!('dauer' in setDefaults)) { setDefaults.dauer = null }
      if (!('mitarbeiter' in setDefaults)) { setDefaults.mitarbeiter = user.userid }
      if (!('taetigkeit' in setDefaults)) { setDefaults.taetigkeit = '' }
      if (!('auto' in setDefaults)) { setDefaults.auto = false }
      if (!('image01' in setDefaults)) { setDefaults.image01 = null }
      if (!('image02' in setDefaults)) { setDefaults.image02 = null }
      if (!('image03' in setDefaults)) { setDefaults.image03 = null }
      if (!('image04' in setDefaults)) { setDefaults.image04 = null }
      if (!('image05' in setDefaults)) { setDefaults.image05 = null }
      return setDefaults
    }
  },
  methods: {
    isAuto: function () {
      let result = false
      if ('auto' in this.localArbeitszeit) {
        result = this.localArbeitszeit.auto
      }
      return result
    },
    getRandomInt (max) {
      return Math.floor(Math.random() * max)
    },
    deleteEntry (entry) {
      this.$emit('remove')
      console.log(entry)
      // document.getElementById(entry).remove()
      this.$eventBus.$emit('remove-time-entry', this.localArbeitszeit)
    }
  }
}
</script>

<style lang="scss">
  .repeater-drop-zone:first-child button[title="Remove block"]{
    display: none !important;
  }
  .repeater-drop-zone button[content="Duplicate"]{
    display: none !important;
  }

  div.material {
    div.bildupload {
      padding-left: 15px;
      padding-right: 15px;
      div.imagecomponent {
        div.form-group {
          div.input {
            overflow: hidden;
            input {
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>
