import Vue from 'vue'
import store from '../store'
import router from '../router'

export default function interceptorSetup () {
  //   ____  _____ _____ ____  _     ___ _   _ _  __   ___  _   _ _   _ _____      _   _   _ _____ _   _
  //  |  _ \| ____| ____|  _ \| |   |_ _| \ | | |/ /  / _ \| | | | \ | | ____|    / \ | | | |_   _| | | |
  //  | | | |  _| |  _| | |_) | |    | ||  \| | ' /  | | | | |_| |  \| |  _|     / _ \| | | | | | | |_| |
  //  | |_| | |___| |___|  __/| |___ | || |\  | . \  | |_| |  _  | |\  | |___   / ___ \ |_| | | | |  _  |
  //  |____/|_____|_____|_|   |_____|___|_| \_|_|\_\  \___/|_| |_|_| \_|_____| /_/   \_\___/  |_| |_| |_|
  //
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters['security/isAuthenticated']) {
        next()
      } else {
        next({ name: 'Login' })
      }
    } else {
      next()
    }
  })
  //   ____   ___  _     _____ ____
  //  |  _ \ / _ \| |   | ____/ ___|
  //  | |_) | | | | |   |  _| \___ \
  //  |  _ <| |_| | |___| |___ ___) |
  //  |_| \_\\___/|_____|_____|____/
  //
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.allowed)) {
      if (store.getters['security/hasRole']('ROLE_ADMIN') || store.getters['security/hasRole']('ROLE_SUPER_ADMIN')) {
        next()
      } else {
        let hasRole = false
        Vue._.forEach(to.meta.allowed, function (role) {
          if (store.getters['security/hasRole'](role)) {
            hasRole = true
          }
        })
        if (!hasRole) {
          next({ name: 'Page403' })
        } else {
          next()
        }
      }
    } else {
      next()
    }
  })
  //  __     ___   _ _______  __
  //  \ \   / / | | | ____\ \/ /
  //   \ \ / /| | | |  _|  \  /
  //    \ V / | |_| | |___ /  \
  //     \_/   \___/|_____/_/\_\
  //
  /**
   * Wenn der State von Vuex leer ist, die entsprechenden Daten laden
   */
  router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && to.name !== 'Logout' && to.name !== 'Password' && to.name !== 'Page403') {
      if (Vue._.isEmpty(store.state.kunden.kunden)) {
        store.dispatch('kunden/REFRESH').then(function (response) {
          next()
        })
      } else {
        next()
      }
    } else {
      next()
    }
  })
}
